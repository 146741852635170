import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

import config from '../../site-config';
import Download from '../components/Dowload';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const DownloadPage = () => {
  const { t } = useTranslation();

  useEffect(
    () => {
      if (isAndroid) {
        window.location.href = config.playStoreLink;
      }

      if (isIOS) {
        window.location.href = config.appStoreLink;
      }
    },
    [],
  );

  return (
    <Layout>
      <SEO title={t('downloadTaggi')} />
      <Download />
    </Layout>
  );
};

export default DownloadPage;
